import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IStudentSizes, defaultValue } from 'app/shared/model/student-sizes.model';

export const ACTION_TYPES = {
  FETCH_STUDENTSIZES_LIST: 'studentSizes/FETCH_STUDENTSIZES_LIST',
  FETCH_STUDENTSIZES: 'studentSizes/FETCH_STUDENTSIZES',
  CREATE_STUDENTSIZES: 'studentSizes/CREATE_STUDENTSIZES',
  UPDATE_STUDENTSIZES: 'studentSizes/UPDATE_STUDENTSIZES',
  PARTIAL_UPDATE_STUDENTSIZES: 'studentSizes/PARTIAL_UPDATE_STUDENTSIZES',
  DELETE_STUDENTSIZES: 'studentSizes/DELETE_STUDENTSIZES',
  RESET: 'studentSizes/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IStudentSizes>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type StudentSizesState = Readonly<typeof initialState>;

// Reducer

export default (state: StudentSizesState = initialState, action): StudentSizesState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_STUDENTSIZES_LIST):
    case REQUEST(ACTION_TYPES.FETCH_STUDENTSIZES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_STUDENTSIZES):
    case REQUEST(ACTION_TYPES.UPDATE_STUDENTSIZES):
    case REQUEST(ACTION_TYPES.DELETE_STUDENTSIZES):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_STUDENTSIZES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_STUDENTSIZES_LIST):
    case FAILURE(ACTION_TYPES.FETCH_STUDENTSIZES):
    case FAILURE(ACTION_TYPES.CREATE_STUDENTSIZES):
    case FAILURE(ACTION_TYPES.UPDATE_STUDENTSIZES):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_STUDENTSIZES):
    case FAILURE(ACTION_TYPES.DELETE_STUDENTSIZES):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_STUDENTSIZES_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_STUDENTSIZES):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_STUDENTSIZES):
    case SUCCESS(ACTION_TYPES.UPDATE_STUDENTSIZES):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_STUDENTSIZES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_STUDENTSIZES):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/student-sizes';

// Actions

export const getEntities = (page?: number, size?: number, sort?: string, finder?: string) => {
  let find = '';
  if (finder) find = `&find=${finder}`;
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}${find}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_STUDENTSIZES_LIST,
    payload: axios.get<IStudentSizes>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IStudentSizes> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_STUDENTSIZES,
    payload: axios.get<IStudentSizes>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IStudentSizes> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_STUDENTSIZES,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const createMany = (entities, studentId) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_STUDENTSIZES,
    payload: axios.post(`${apiUrl}/many`, entities),
  });
  dispatch(getFromStudent(studentId));
  return result;
};

export const getFromStudent = studentId => {
  return {
    type: ACTION_TYPES.FETCH_STUDENTSIZES_LIST,
    payload: axios.get<IStudentSizes>(`${apiUrl}/fromStudent/${studentId}`),
  };
};

export const updateEntity: ICrudPutAction<IStudentSizes> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_STUDENTSIZES,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IStudentSizes> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_STUDENTSIZES,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IStudentSizes> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_STUDENTSIZES,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
