import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className="footer-container d-flex flex-row justify-content-center  align-items-center">
      <h4 className="my-0 py-3 font-weight-bolder">COMUNICATE CON NOSOTROS!</h4>
      {/* <a href="https://www.instagram.com/mashipa.egresados/?hl=es" target="_blank" rel="noreferrer">
        <img src="../../../../content/images/iconos/whatsapp.png" alt="Facebook Icon" className="footer-icon" />
      </a> */}
      <a href="https://www.instagram.com/mashipa.egresados/?hl=es" target="_blank" rel="noreferrer">
        <img src="../../../../content/images/iconos/instagram.png" alt="Facebook Icon" className="footer-icon" />
      </a>
      <a href="https://www.tiktok.com/@mashipa_egresados" target="_blank" rel="noreferrer">
        <img src="../../../../content/images/iconos/facebook.png" alt="Facebook Icon" className="footer-icon" />
      </a>
    </div>
  );
};

export default Footer;
