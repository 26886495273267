import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import administrator, {
  AdministratorState
} from 'app/entities/administrator/administrator.reducer';
// prettier-ignore
import schoolPromotion, {
  SchoolPromotionState
} from 'app/entities/school-promotion/school-promotion.reducer';
// prettier-ignore
import student, {
  StudentState
} from 'app/entities/student/student.reducer';
// prettier-ignore
import clothes, {
  ClothesState
} from 'app/entities/clothes/clothes.reducer';
// prettier-ignore
import combo, {
  ComboState
} from 'app/entities/combo/combo.reducer';
// prettier-ignore
import sizes, {
  SizesState
} from 'app/entities/sizes/sizes.reducer';
// prettier-ignore
import studentSizes, {
  StudentSizesState
} from 'app/entities/student-sizes/student-sizes.reducer';
// prettier-ignore
import adultInCharge, {
  AdultInChargeState
} from 'app/entities/adult-in-charge/adult-in-charge.reducer';
// prettier-ignore
import question, {
  QuestionState
} from 'app/entities/question/question.reducer';
// prettier-ignore
import questionCategory, {
  QuestionCategoryState
} from 'app/entities/question-category/question-category.reducer';
// prettier-ignore
import payments, {
  PaymentsState
} from 'app/entities/payments/payments.reducer';
// prettier-ignore
import paymentOptions, {
  PaymentOptionsState
} from 'app/entities/payment-options/payment-options.reducer';
// prettier-ignore
import paymentAccounts, {
  PaymentAccountsState
} from 'app/entities/payment-accounts/payment-accounts.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly administrator: AdministratorState;
  readonly schoolPromotion: SchoolPromotionState;
  readonly student: StudentState;
  readonly clothes: ClothesState;
  readonly combo: ComboState;
  readonly sizes: SizesState;
  readonly studentSizes: StudentSizesState;
  readonly adultInCharge: AdultInChargeState;
  readonly question: QuestionState;
  readonly questionCategory: QuestionCategoryState;
  readonly payments: PaymentsState;
  readonly paymentOptions: PaymentOptionsState;
  readonly paymentAccounts: PaymentAccountsState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  administrator,
  schoolPromotion,
  student,
  clothes,
  combo,
  sizes,
  studentSizes,
  adultInCharge,
  question,
  questionCategory,
  payments,
  paymentOptions,
  paymentAccounts,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
