import React, { useEffect } from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { NavDropdown } from './menu-components';
import { connect } from 'react-redux';
import { getEntityFromUser } from 'app/entities/student/student.reducer';
import { IRootState } from 'app/shared/reducers';
import { StudentType } from 'app/shared/model/enumerations/student-type.model';
import { AUTHORITIES } from 'app/config/constants';

import './student-menu.scss';

export const StudentMenuReact = ({ profile, student, getStudent }) => {
  useEffect(() => {
    if (profile?.id) getStudent(profile.id);
  }, []);

  return (
    <NavDropdown
      id="entity-menu"
      data-cy="entity"
      style={{ position: 'relative', top: -15, display: 'flex', backgroundColor: 'rgb(255, 107, 52)', gap: 15 }}
    >
      {profile?.authorities?.findIndex(auth => auth === AUTHORITIES.TALLES) >= 0 ? (
        <MenuItem icon="asterisk" to="/send-sizes">
          Enviar Talles
        </MenuItem>
      ) : null}
      <MenuItem icon="asterisk" to="/student/planilla">
        Info. Alumno
      </MenuItem>
      {student.type === StudentType.PRESENCIAL ? null : (
        <MenuItem icon="asterisk" to="/student/talle">
          Talles
        </MenuItem>
      )}
      {student.delegate && (
        <MenuItem icon="asterisk" to="/student/schoolPromotion/status">
          Promoción
        </MenuItem>
      )}
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </NavDropdown>
  );
};

const mapDispatchToProps = {
  getStudent: getEntityFromUser,
};

const mapStateToProps = ({ student, authentication }: IRootState) => {
  return {
    student: student.entity,
    profile: authentication.account,
  };
};

export const StudentMenu = connect(mapStateToProps, mapDispatchToProps)(StudentMenuReact);
