import { IStudent } from 'app/shared/model/student.model';
import { IPaymentOptions } from 'app/shared/model/payment-options.model';

export interface IPayments {
  id?: number;
  amount?: number | null;
  instalment?: number | null;
  confirmationId?: string | null;
  payedAt?: Date | null;
  lastSelection?: Date | null;
  groupId?: string | null;
  status?: string | null;
  student?: IStudent | null;
  paymentOption?: IPaymentOptions | null;
}

export const defaultValue: Readonly<IPayments> = {};
