import { IClothes } from 'app/shared/model/clothes.model';

export interface ISizes {
  id?: number;
  size?: string | null;
  length?: number | null;
  width?: number | null;
  sleeveLength?: number | null;
  visibleName?: string | null;
  clothes?: IClothes[] | null;
}

export const defaultValue: Readonly<ISizes> = {};
