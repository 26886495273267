import { ISizes } from 'app/shared/model/sizes.model';
import { ICombo } from 'app/shared/model/combo.model';

export interface IClothes {
  id?: number;
  name?: string | null;
  description?: string | null;
  imageSrc?: string | null;
  sizes?: ISizes[] | null;
  combos?: ICombo[] | null;
}

export const defaultValue: Readonly<IClothes> = {};
