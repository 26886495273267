import { IStudent } from 'app/shared/model/student.model';
import { ICombo } from 'app/shared/model/combo.model';
import { IClothes } from 'app/shared/model/clothes.model';

export interface IStudentSizes {
  id?: number;
  size?: string | null;
  length?: number | null;
  width?: number | null;
  sleeveLength?: number | null;
  nickname?: string | null;
  student?: IStudent | null;
  combo?: ICombo | null;
  clothes?: IClothes | null;
}

export const defaultValue: Readonly<IStudentSizes> = {};
