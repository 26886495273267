export interface IPaymentAccounts {
  id?: number;
  name?: string | null;
  accountType?: string | null;
  apiKey?: string | null;
  accountId?: string | null;
  cuitId?: string | null;
  apiUser?: string | null;
}

export const defaultValue: Readonly<IPaymentAccounts> = {};
