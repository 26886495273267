import { IUser } from 'app/shared/model/user.model';
import { ISchoolPromotion } from 'app/shared/model/school-promotion.model';
import { ICombo } from 'app/shared/model/combo.model';
import { StudentType } from 'app/shared/model/enumerations/student-type.model';
import { AmountFees } from 'app/shared/model/enumerations/amount-fees.model';
import { PayMethods } from 'app/shared/model/enumerations/pay-methods.model';

export interface IStudent {
  id?: number;
  name?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  document?: string | null;
  delegate?: boolean | null;
  type?: StudentType | null;
  amountFees?: AmountFees | null;
  payMethod?: PayMethods | null;
  payLink?: string | null;
  price?: number | null;
  pricePercentage?: number | null;
  specialSize?: boolean | null;
  freeStudent?: boolean | null;
  duplicatedStudent?: boolean | null;
  contract?: boolean | null;
  user?: IUser | null;
  schoolPromotion?: ISchoolPromotion | null;
  combo?: ICombo | null;
}

export const defaultValue: Readonly<IStudent> = {
  delegate: false,
  contract: false,
};
