import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AdminMenu, EntitiesMenu, StudentMenu } from '../menus';
import './header.css';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  profile: any;
}

const Header = (props: IHeaderProps) => {
  const [isDropdownActive, setDropdownActive] = useState(false);

  const handlerDropdown = () => {
    setDropdownActive(!isDropdownActive);
  };
  return (
    <>
      <Navbar sticky="top" className=" d-flex flex-row header-container w-100 py-0">
        <Link to="/">
          <img src="../../../../content/headerImages/logomashipa.jpg" alt="" className="logo-mashipa" />
        </Link>
        {props.isAuthenticated ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: 25, marginRight: 10 }}>
            <Link style={{ color: '#ff6b34', textDecoration: 'none', fontSize: 13 }} to="/help">
              Necesito ayuda
            </Link>
            <ButtonDropdown
              isOpen={isDropdownActive}
              toggle={handlerDropdown}
              direction={'down'}
              style={{ minWidth: 100, maxWidth: 300, width: '20%', fontSize: 13 }}
            >
              <DropdownToggle style={{ backgroundColor: '#ff6b34', minWidth: 100, maxWidth: 300, width: '20%', borderRadius: 15 }}>
                <FontAwesomeIcon icon={faUser} />
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: 5, textAlign: 'center', width: 400 }}>
                <DropdownItem text={true}>
                  {props.profile.email ? props.profile.email : `${props.profile.firstName} ${props.profile.lastName}`}
                </DropdownItem>
                <Link to="/logout" className="link-autogestion">
                  <DropdownItem>Cerrar Sesión</DropdownItem>
                </Link>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginRight: 10 }}>
            <Link style={{ color: '#ff6b34', textDecoration: 'none', fontSize: 13 }} to="/help">
              Necesito ayuda
            </Link>
            <Link
              style={{ color: '#ffffff', textDecoration: 'none', backgroundColor: '#ff6b34', borderRadius: 7, padding: 10, fontSize: 13 }}
              to="/login"
            >
              Ingresar
            </Link>
          </div>
        )}
        {props.isAuthenticated &&
          (props.isAdmin ? (
            <div
              style={{
                display: 'flex',
                listStyle: 'none',
                backgroundColor: 'rgb(255, 107, 52)',
                border: '1px solid rgb(255, 107, 52)',
                justifyContent: 'center',
                width: '100%',
                borderTop: '1px solid black',
              }}
            >
              <EntitiesMenu />
              <AdminMenu showOpenAPI={false}></AdminMenu>
            </div>
          ) : null)}
        {props.isAuthenticated && props.profile.authorities.some(auth => auth === 'ROLE_STUDENT') ? (
          <div
            style={{
              display: 'flex',
              listStyle: 'none',
              backgroundColor: 'rgb(255, 107, 52)',
              border: '1px solid rgb(255, 107, 52)',
              justifyContent: 'center',
              width: '100%',
              borderTop: '1px solid black',
              height: 85,
            }}
          >
            <StudentMenu />
          </div>
        ) : null}
      </Navbar>
    </>
  );
};

export default Header;
