import { IClothes } from 'app/shared/model/clothes.model';

export interface ICombo {
  id?: number;
  name?: string | null;
  value?: number | null;
  codred?: string | null;
  clothes?: IClothes[] | null;
}

export const defaultValue: Readonly<ICombo> = {};
