import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown icon="th-list" name="Menú" id="entity-menu" data-cy="entity" style={{ maxHeight: '80vh', overflow: 'auto' }}>
    <MenuItem icon="asterisk" to="/school-promotion">
      Promociones de Escuelas
    </MenuItem>
    <MenuItem icon="asterisk" to="/student">
      Estudiantes
    </MenuItem>
    <MenuItem icon="asterisk" to="/clothes">
      Prendas
    </MenuItem>
    <MenuItem icon="asterisk" to="/combo">
      Combos
    </MenuItem>
    <MenuItem icon="asterisk" to="/sizes">
      Talles
    </MenuItem>
    <MenuItem icon="asterisk" to="/student-sizes">
      Talles de Estudiantes
    </MenuItem>
    <MenuItem icon="asterisk" to="/adult-in-charge">
      Adultos a Cargo
    </MenuItem>
    <MenuItem icon="asterisk" to="/question">
      Preguntas
    </MenuItem>
    <MenuItem icon="asterisk" to="/question-category">
      Categoria de Preguntas
    </MenuItem>
    <MenuItem icon="asterisk" to="/payments">
      Pagos
    </MenuItem>
    <MenuItem icon="asterisk" to="/payment-accounts">
      Cuentas de cobro
    </MenuItem>
    <MenuItem icon="asterisk" to="/reports">
      Informes
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
