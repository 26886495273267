import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import DevTools from './config/devtools';
import initStore from './config/store';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { ToastContainer } from 'react-toastify';

const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

const store = initStore();

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();

const rootEl = document.getElementById('root');

const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="light"
          // transition: Bounce,
        />
        {/* Same as */}
        <ToastContainer />
        <div>
          {/* If this slows down the app in dev disable it and enable when required  */}
          {/* {devTools} */}
          <Component />
        </div>
        <FloatingWhatsApp
          onClick={() => (window as any).dataLayer && (window as any).dataLayer.push({ event: 'whatsapp-click' })}
          accountName="Mashipa Egresados"
          phoneNumber="5493517609359"
          chatMessage="Hola! 👋🏼 Bienvenido a Mashipa Egresados! ¿Cómo te podemos ayudar?"
          statusMessage="En linea"
          placeholder="Escribe un mensaje"
        />
      </Provider>
    </ErrorBoundary>,
    rootEl
  );

render(AppComponent);
