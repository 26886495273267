import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ISchoolPromotion, defaultValue } from 'app/shared/model/school-promotion.model';

export const ACTION_TYPES = {
  FETCH_SCHOOLPROMOTION_LIST: 'schoolPromotion/FETCH_SCHOOLPROMOTION_LIST',
  FETCH_SCHOOLPROMOTION: 'schoolPromotion/FETCH_SCHOOLPROMOTION',
  CREATE_SCHOOLPROMOTION: 'schoolPromotion/CREATE_SCHOOLPROMOTION',
  UPDATE_SCHOOLPROMOTION: 'schoolPromotion/UPDATE_SCHOOLPROMOTION',
  PARTIAL_UPDATE_SCHOOLPROMOTION: 'schoolPromotion/PARTIAL_UPDATE_SCHOOLPROMOTION',
  DELETE_SCHOOLPROMOTION: 'schoolPromotion/DELETE_SCHOOLPROMOTION',
  RESET: 'schoolPromotion/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ISchoolPromotion>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type SchoolPromotionState = Readonly<typeof initialState>;

// Reducer

export default (state: SchoolPromotionState = initialState, action): SchoolPromotionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SCHOOLPROMOTION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SCHOOLPROMOTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_SCHOOLPROMOTION):
    case REQUEST(ACTION_TYPES.UPDATE_SCHOOLPROMOTION):
    case REQUEST(ACTION_TYPES.DELETE_SCHOOLPROMOTION):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_SCHOOLPROMOTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_SCHOOLPROMOTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SCHOOLPROMOTION):
    case FAILURE(ACTION_TYPES.CREATE_SCHOOLPROMOTION):
    case FAILURE(ACTION_TYPES.UPDATE_SCHOOLPROMOTION):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_SCHOOLPROMOTION):
    case FAILURE(ACTION_TYPES.DELETE_SCHOOLPROMOTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCHOOLPROMOTION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCHOOLPROMOTION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SCHOOLPROMOTION):
    case SUCCESS(ACTION_TYPES.UPDATE_SCHOOLPROMOTION):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_SCHOOLPROMOTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_SCHOOLPROMOTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/school-promotions';

// Actions

export const getEntities = (page?: number, size?: number, sort?: string, finder?: string) => {
  let find = '';
  if (finder) find = `&find=${finder}`;
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}${find}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SCHOOLPROMOTION_LIST,
    payload: axios.get<ISchoolPromotion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getSchoolNames = () => {
  const requestUrl = `${apiUrl}?fields=name,id`;
  return {
    type: ACTION_TYPES.FETCH_SCHOOLPROMOTION_LIST,
    payload: axios.get<ISchoolPromotion>(`${requestUrl}&cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ISchoolPromotion> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SCHOOLPROMOTION,
    payload: axios.get<ISchoolPromotion>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ISchoolPromotion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SCHOOLPROMOTION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ISchoolPromotion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SCHOOLPROMOTION,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const validateDesign: ICrudPutAction<ISchoolPromotion> = entity => async dispatch => {
  const updatedEntity = {
    id: entity.id,
    validatedDesign: true,
  };
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SCHOOLPROMOTION,
    payload: axios.put(`${apiUrl}/validateDesign/${updatedEntity.id}`, cleanEntity(updatedEntity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<ISchoolPromotion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_SCHOOLPROMOTION,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ISchoolPromotion> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SCHOOLPROMOTION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
