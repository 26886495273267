import { ISchoolPromotion } from 'app/shared/model/school-promotion.model';
import { IPaymentAccounts } from 'app/shared/model/payment-accounts.model';
import { ICombo } from 'app/shared/model/combo.model';

export interface IPaymentOptions {
  id?: number;
  instalments?: number | null;
  total_price?: number | null;
  first_instalment_weight?: number | null;
  school?: ISchoolPromotion | null;
  account?: IPaymentAccounts | null;
  combo?: ICombo | null;
  schoolId?: number;
  accountId?: number;
  comboId?: number;
}

export const defaultValue: Readonly<IPaymentOptions> = {};
