import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IStudent, defaultValue } from 'app/shared/model/student.model';
import { toast } from 'react-toastify';

export const ACTION_TYPES = {
  FETCH_STUDENT_LIST: 'student/FETCH_STUDENT_LIST',
  FETCH_STUDENT: 'student/FETCH_STUDENT',
  CREATE_STUDENT: 'student/CREATE_STUDENT',
  CREATE_STUDENTS: 'student/CREATE_STUDENTS',
  UPDATE_STUDENT: 'student/UPDATE_STUDENT',
  PARTIAL_UPDATE_STUDENT: 'student/PARTIAL_UPDATE_STUDENT',
  DELETE_STUDENT: 'student/DELETE_STUDENT',
  RESET: 'student/RESET',
  PAYMENT_LINK: 'student/PAYMENT_LINK',
  PAYMENT_CUPON: 'student/PAYMENT_CUPON',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IStudent>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  paymentLink: '',
  lastLink: Date.now(),
};

export type StudentState = Readonly<typeof initialState>;

// Reducer

export default (state: StudentState = initialState, action): StudentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_STUDENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_STUDENT):
    case REQUEST(ACTION_TYPES.PAYMENT_LINK):
    case REQUEST(ACTION_TYPES.PAYMENT_CUPON):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_STUDENT):
    case REQUEST(ACTION_TYPES.CREATE_STUDENTS):
    case REQUEST(ACTION_TYPES.UPDATE_STUDENT):
    case REQUEST(ACTION_TYPES.DELETE_STUDENT):
    case REQUEST(ACTION_TYPES.PARTIAL_UPDATE_STUDENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.PAYMENT_LINK):
    case FAILURE(ACTION_TYPES.PAYMENT_CUPON):
      return {
        ...state,
        errorMessage: 'NO SE PUDO GENERAR LINK DE PAGO',
        updateSuccess: false,
        updating: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_STUDENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_STUDENT):
    case FAILURE(ACTION_TYPES.CREATE_STUDENT):
    case FAILURE(ACTION_TYPES.CREATE_STUDENTS):
    case FAILURE(ACTION_TYPES.UPDATE_STUDENT):
    case FAILURE(ACTION_TYPES.PARTIAL_UPDATE_STUDENT):
    case FAILURE(ACTION_TYPES.DELETE_STUDENT):
      toast(action.payload.response.data.error || action.payload.message);
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_STUDENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_STUDENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_STUDENT):
    case SUCCESS(ACTION_TYPES.CREATE_STUDENTS):
    case SUCCESS(ACTION_TYPES.UPDATE_STUDENT):
    case SUCCESS(ACTION_TYPES.PARTIAL_UPDATE_STUDENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_STUDENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case SUCCESS(ACTION_TYPES.PAYMENT_CUPON):
      return {
        ...state,
        loading: false,
        paymentLink: action.payload.data,
        lastLink: Date.now(),
      };
    case SUCCESS(ACTION_TYPES.PAYMENT_LINK):
      return {
        ...state,
        loading: false,
        paymentLink: action.payload.data,
        lastLink: Date.now(),
      };
    default:
      return state;
  }
};

const apiUrl = 'api/students';

// Actions

export const getEntities = (page?: number, size?: number, sort?: string, finder?: string) => {
  let find = '';
  if (finder) find = `&find=${finder}`;
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}${find}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_STUDENT_LIST,
    payload: axios.get<IStudent>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IStudent> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_STUDENT,
    payload: axios.get<IStudent>(requestUrl),
  };
};

export const getPaymentLink = (id, option, installment) => {
  const requestUrl = `${apiUrl}/${id}/paymentLinks?paymentOption=${option}&installment=${installment}`;
  return {
    type: ACTION_TYPES.PAYMENT_LINK,
    payload: axios.get(requestUrl),
  };
};

export const getPaymentCupon = (id, option, installment) => {
  const requestUrl = `${apiUrl}/${id}/payment/barcode?paymentOption=${option}&installment=${installment}`;
  return {
    type: ACTION_TYPES.PAYMENT_CUPON,
    payload: axios.get(requestUrl),
  };
};

export const getEntityFromUser: ICrudGetAction<IStudent> = id => {
  const requestUrl = `${apiUrl}/fromUser/${id}`;
  return {
    type: ACTION_TYPES.FETCH_STUDENT,
    payload: axios.get<IStudent>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IStudent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_STUDENT,
    payload: axios.post(`${apiUrl}/saveMany`, [entity]),
  });
  dispatch(getEntities(0, 20, 'id,asc'));
  return result;
};

export const createManyEntities = entities => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_STUDENTS,
    payload: axios.post(`${apiUrl}/saveMany`, entities),
  });
  dispatch(getEntities(0, 20, 'id,asc'));
  return result;
};

export const updateEntity: ICrudPutAction<IStudent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_STUDENT,
    payload: axios.put(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const partialUpdate: ICrudPutAction<IStudent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.PARTIAL_UPDATE_STUDENT,
    payload: axios.patch(`${apiUrl}/${entity.id}`, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IStudent> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_STUDENT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities(0, 20, 'id,asc'));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
