import { IUser } from 'app/shared/model/user.model';
import { ISchoolPromotion } from 'app/shared/model/school-promotion.model';

export interface IAdultInCharge {
  id?: number;
  name?: string | null;
  lastName?: string | null;
  document?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  contract?: boolean | null;
  user?: IUser | null;
  schoolPromotion?: ISchoolPromotion | null;
}

export const defaultValue: Readonly<IAdultInCharge> = {
  contract: false,
};
