import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import { AUTHORITIES } from 'app/config/constants';
import PrivateRoute from './shared/auth/private-route';
import ErrorBoundaryRoute from './shared/error/error-boundary-route';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Login = React.lazy(() => import(/* webpackChunkName: "login" */ 'app/modules/login/login'));
const Loged = React.lazy(() => import(/* webpackChunkName: "loged" */ './modules/login/loged'));
const PaymentFailed = React.lazy(() => import(/* webpackChunkName: "failure" */ './modules/payment/failed'));
const PaymentSuccess = React.lazy(() => import(/* webpackChunkName: "success" */ './modules/payment/success'));
const PaymentPending = React.lazy(() => import(/* webpackChunkName: "pending" */ './modules/payment/pending'));

const Help = React.lazy(() => import(/* webpackChunkName: "help" */ 'app/shared/help'));

const PageNotFound = React.lazy(() => import(/* webpackChunkName: "page-not-found" */ 'app/shared/error/page-not-found'));

// const PrivateRoute = React.lazy(() =>
//   import(/* webpackChunkName: "private-route" */ 'app/shared/auth/private-route')
// )

const Entities = React.lazy(() => import(/* webpackChunkName: "entities" */ 'app/entities'));

const Home = React.lazy(() => import(/* webpackChunkName: "home" */ 'app/modules/home/home'));

const Logout = React.lazy(() => import(/* webpackChunkName: "logout" */ 'app/modules/login/logout'));

const PasswordResetFinish = React.lazy(
  () => import(/* webpackChunkName: "password-reset-finish" */ 'app/modules/account/password-reset/finish/password-reset-finish')
);

const PasswordResetInit = React.lazy(
  () => import(/* webpackChunkName: "password-reset" */ 'app/modules/account/password-reset/init/password-reset-init')
);

const Activate = React.lazy(() => import(/* webpackChunkName: "activate" */ 'app/modules/account/activate/activate'));

const Register = React.lazy(() => import(/* webpackChunkName: "register" */ 'app/modules/account/register/register'));

const Routes = () => {
  return (
    <div className="view-routes">
      <Switch>
        <Route path="/login" render={props => <Login {...props} />} />
        <Route path="/logout" render={props => <Logout {...props} />} />
        <Route path="/loged" render={props => <Loged {...props} />} />
        <Route path="/payment/success" render={props => <PaymentSuccess {...props} />} />
        <Route path="/payment/failure" render={props => <PaymentFailed {...props} />} />
        <Route path="/payment/pending" render={props => <PaymentPending {...props} />} />
        <Route path="/account/register" render={props => <Register {...props} />} />
        <Route path="/account/activate/:key?" render={props => <Activate {...props} />} />
        <Route path="/account/reset/request" render={props => <PasswordResetInit {...props} />} />
        <Route path="/account/reset/finish/:key?" render={props => <PasswordResetFinish {...props} />} />

        <PrivateRoute path="/admin" component={props => <Admin {...props} />} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute
          path="/account"
          component={props => <Account {...props} />}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.STUDENT]}
        />
        <ErrorBoundaryRoute path={`/help`} component={props => <Help {...props} />} />
        <ErrorBoundaryRoute path="/" exact component={props => <Home {...props} />} />
        <PrivateRoute
          path="/"
          component={props => <Entities {...props} />}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.STUDENT]}
        />
        <ErrorBoundaryRoute component={props => <PageNotFound {...props} />} />
      </Switch>
    </div>
  );
};

export default Routes;
