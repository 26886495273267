import { IQuestion } from 'app/shared/model/question.model';

export interface IQuestionCategory {
  id?: number;
  name?: string | null;
  priority?: number | null;
  questions?: IQuestion[] | null;
}

export const defaultValue: Readonly<IQuestionCategory> = {};
