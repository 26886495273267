import { IQuestionCategory } from 'app/shared/model/question-category.model';

export interface IQuestion {
  id?: number;
  title?: string | null;
  description?: string | null;
  priority?: string | null;
  categories?: IQuestionCategory[] | null;
}

export const defaultValue: Readonly<IQuestion> = {};
